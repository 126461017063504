.coachBoxContainer {
  display: flex;
  gap: 10px;
  padding: 2rem;
  flex-wrap: wrap;
}
.coachBoxHeader{
  display: flex;
  padding: 2;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
.coachBoxHeader h1{
  color: var(--dark_primary_text_color);
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 1px;
}
.coachBoxHeader button{
  background-color: var(--dark_primary_text_color);
  color: white;
  padding: 10px 15px;
}