.edit_user_form,
.edit_debate_page {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.input_item {
  background-color: #9f9f9f3d;
  height: 50px;
}
.input_item input,
.input_item select,
.teamBox input {
  background-color: transparent;
  border-radius: 5px;
  padding: 0 1rem;
  border: 1px solid gray;
  width: 100%;
  height: 100%;
  color: gray;
}
.label {
  font-size: 17px;
  letter-spacing: 1px;
  color: gray;
}
.check_box_input {
  width: 25px;
  height: 25px;
  border: 2px solid gray;
  background-color: rgb(242, 242, 242);
}
.normal_item {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.update_button {
  background-color: var(--primary_color);
  border-radius: 3px;
  font-size: 16px;
  letter-spacing: 1px;
  height: 45px;
  padding: 0 10px;
  color: white;
}
.teamBox {
  display: flex;
  margin-top: 3rem;
  flex-direction: column;
  position: relative;
  height: auto;
  gap: 1rem;
}
.teamBox input {
  height: 45px;
  background-color: #9f9f9f3d;
}
.team_box_item {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 10px;
}

.date_picker {
  background-color: #9f9f9f3d;
  cursor: pointer;
  margin-left: 4px;
  border-radius: 4px;
}
