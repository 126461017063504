@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Ysabeau:wght@700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Poppins", sans-serif;
}
h5 {
  margin-bottom: 0 !important;
}
:root {
  --dark_primary_text_color: rgb(14, 11, 61);
  --dark_primary_text_color_hover: rgba(14, 11, 61, 0.774);
  --light_primary_text_color: #625959;
  --dark_gradient_bg: linear-gradient(
    45deg,
    rgba(174, 174, 174, 0.21),
    rgba(64, 70, 221, 0.17),
    rgba(174, 174, 174, 0.21)
  );
  --light_gradient_bg: linear-gradient(
    45deg,
    rgba(174, 174, 174, 0.21),
    rgba(64, 70, 221, 0.12)
  );
  --secondary_gradient_bg: linear-gradient(
      45deg,
      #f709af1a,
      rgb(183 183 195 / 12%),
      #f709af21
    ),
    #f709af05;
  --primary_secondary_gradient_bg: linear-gradient(
    70deg,
    #f709af1a,
    rgba(64, 70, 221, 0.12)
  );
  --primary_color: rgb(104, 109, 224);
  --primary_color_dark: rgb(26, 33, 201);
  --secondary_color: #f709af;
  --primary_dark_color: rgb(101, 16, 238);
}

.main_text {
  display: flex;
  gap: 10px;
  color: var(--primary_color);
  font-size: 40px;
  font-weight: bolder;
  width: fit-content;
  position: relative;
}
.main_text::after {
  content: "";
  bottom: 0;
  width: 100%;
  right: 0;
  position: absolute;
  height: 4px;
  border-radius: 10px;
  background: linear-gradient(
    45deg,
    rgba(174, 174, 174, 0.11),
    rgba(174, 174, 174, 0.21),
    rgb(64 70 221 / 52%),
    rgb(64 70 221 / 62%)
  );
}

.secondary_main_text {
  font-size: 40px;
  color: var(--dark_primary_text_color) !important;
}
.header_text_with_bg {
  background: var(--dark_gradient_bg);
  width: fit-content;
  padding: 7px;
  border-radius: 6px;
  font-size: 10px;
  color: #625959;
  cursor: pointer;
  letter-spacing: 1px;
}
.App {
  background-color: rgba(255, 255, 255);
  width: 100%;
  margin: 0 auto;
  /* max-width: 1200px; */
  padding: 0;
  /* overflow: hidden; */
}
.avatar_item {
  transition: all 0.3s ease;
  cursor: pointer;
}
.avatar_item:hover {
  scale: 1.01;
}
.profile_popover_button {
  font-weight: 200 !important ;
  letter-spacing: 1px !important;
  background: var(--primary_color) !important;
}
p,
h1,
ul {
  margin-bottom: 0 !important;
}
.css-4t6ey5[data-status="complete"] {
  background-color: rgba(64, 70, 221, 0.52) !important;
}
.css-kq5oaw[data-status="complete"] {
  background-color: rgba(64, 70, 221, 0.52) !important;
}

.css-kq5oaw[data-status="active"] {
  border-color: rgba(64, 70, 221, 0.52) !important;
}
.ant-tabs-tab-active {
  background-color: var(--primary_color) !important;
}
.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}
/* .ant-tabs-tab-active  */

.timeZoneBox {
  margin: auto;
  text-align: center;
}

.getTimeZoneButton {
  background-color: black;
  padding: 0 2rem;
  height: 45px;
  border-radius: 2px;
  color: white;
}

.debate_cover_img {
  width: 100%;
  height: 60vh;
  border-radius: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 20px;
}

input,
select {
  border: none !important;
}
