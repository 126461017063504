.DebateCardWrapper {
  padding:1.54rem 2rem;
  flex: 1;
  min-width: 350px;

  border-radius: 10px;
  
  display: flex;

  justify-content: space-between;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background-color: #FFFFFF;
}
.DebateCardWrapper:hover {
  filter: blur(0px);
}
.DebateCardWrapper:active {
  filter: blur(0px);
}
.card_top_box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
.debate_topic {
  font-size: 15px;
  

  color: rgb(14, 11, 61);
  letter-spacing: 1px;
  text-transform: uppercase;
   display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis; 
}
.debate_card_bottom {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
}
.avatars {
  width: 40px;
  height: 40px;
}
.debate_more_details {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  gap: 0.71rem;
  padding-bottom: 10px;
}
.debate_details_left,
.debate_details_right {
  display: flex;
  flex-direction: column;
  gap: 0.41rem;
}
.info_key {
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  letter-spacing: 0.9px;
  align-items: center;
  display: flex;
  gap: 3.5rem;
}
.info_key p:nth-of-type(1) {
  font-size: 13px;
  color: gray;
  

}
.info_key p:nth-of-type(2) {
  flex: 5;
}

/* .info_value{
            flex: 4;
            background-color: red;
            text-align: start;
        } */
.started_time {
  font-size: 11px;
  right: 12px;
  letter-spacing: 1px;
  /* color: rgb(104, 109, 224); */

  bottom: 5px;
}
.end_time {
  font-size: 11px;
  right: 12px;
  letter-spacing: 1px;
  color: red;
  bottom: 5px;
}
.debate_button_box {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.DebateCardWrapper button {
  width: fit-content;
  padding: 0 20px;
  height: 40px;
  border-radius:5px;
  font-size: 14px;
  letter-spacing: 1px;
  
  display: flex;
  align-items: center;
  gap: 10px;
  
  color: var(--primary_color);
  transition: all 0.3s;
}
.DebateCardWrapper button:hover {
  
  /* background: rgb(56, 46, 201) !important; */
  scale: 1.04;

}
.debate_button_box .button:active {
  scale: 1.11;
}
.DebateCardWrapper button svg {
  font-size: 16px !important;
  color: var(--primary_color);
}
.delete_debate_btn {
  align-items: center;
  display: flex;
  justify-content: center;
  color: white !important;
  border: none;
  gap: 5px !important;
  padding: 0 10px !important;
  opacity: 0.8;
  background-color:red !important;
  transition: all 0.3s ease;
}
.delete_debate_btn:hover {
  opacity: 1;
}
.delete_debate_btn svg {
  font-size: 15px;
  fill: white;
}
.debate_card_info_box{
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0 !important;
  flex: 1;
}
.debate_card_info_box img{
  
  width: 22px;
}

@media only screen and (min-width: 600px)  and (max-width: 769px){

  .debate_topic {
    font-size: 13px;
  }
  .info_key {
    font-size: 11px;
}

.DebateCardWrapper button {
  padding: 0 9px;
  height: 36px;
  font-size: 11px;
}
.participantsAvatar{
  width: 2.25rem !important;
  height: 2.25rem !important;
}
.participants_avatar_group span:nth-of-type(1){
  
  width: 2.25rem !important;
  height: 2.25rem !important;
}
.DebateCardWrapper {
  padding: 0.8rem 0.8rem;

}
}
@media only screen and (min-width: 480px) and (max-width: 600px) {

  .debate_topic {
    font-size: 13px;
  }
  .participantsAvatar{
    width: 1.75rem !important;
    height: 1.75rem !important;
  }
  .participants_avatar_group span:nth-of-type(1){
    
    width: 1.75rem !important;
    height: 1.75rem !important;
  }
  .DebateCardWrapper {
    padding: 0.6rem 0.9rem;
}
.DebateCardWrapper button {
  padding: 0 9px;
  height: 35px;
  font-size: 10px;
}
.info_key {
  font-size: 10px;
}
}

@media only screen and (max-width: 480px){

  .debate_topic {
    font-size: 11px;
  }
  .participantsAvatar{
    width: 1.70rem !important;
    height: 1.70rem !important;
  }
  .participants_avatar_group span:nth-of-type(1){
    
    width: 1.70rem !important;
    height: 1.70rem !important;
  }
  .info_key {
    font-size: 9px;
  }
  .DebateCardWrapper {
    padding: 0.6rem 0.9rem;
}
.DebateCardWrapper button {
  padding: 0 9px;
  height: 33px;
  font-size: 9px;
}

}